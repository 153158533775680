import React, { useState } from "react";
import styled from 'styled-components';
import xis from '../assets/menu/x.png';

const Container = styled.div`
    height: 100vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: 1.2em;
    font-weight: bold;
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: #fdfdfd;
    box-shadow: 0px 0px 25px 20px rgba(0,0,0,0.5);
    z-index: 2;

`;

const Links = styled.a`
    color: #8D807D;
    height: 10%;
    width: 100%;
    text-align: center;
    padding-top: 1.5em;

    &:hover {
        background-color: #BA292F;
        color: #fdfdfd;
        cursor: pointer;
        transition: 0.85s;
    }
`;

const Botao = styled.img`
    width: 40px;
    height: 40px;
    filter: invert(40%);
    margin: 30px 0;
    margin-left: 70%;
`;



const Menu = () => {

    const [showContainer, setShow] = useState(true);

    return(<>
    {showContainer ? 
        <Container>
                <Botao src={xis} 
                    onClick={() => {
                        setShow(false);
                    }}
                />
                <Links href={'#inicio'}>Início</Links>
                <Links href={'#plenario'}>Plenário</Links>
                <Links href={'#servicos'}>Serviços</Links>
                <Links href={'#clientes'}>Clientes</Links>
                <Links href={'#contato'}>Contato</Links>
        </Container> : null}
        </>
    );
}

export default Menu;
