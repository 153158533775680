import React from "react";
import styled from "styled-components";

import teste from '../assets/teste.png';
import telefone from '../assets/contato/telefone.png';
import email from '../assets/contato/email.png'
import marcador from '../assets/contato/marcador.png'

const Secao = styled.section`
    width: 100%;
    height: auto;
    background-color: #f7f7f7;
    height: auto;
    color: #7a7a7a;
    @media(max-width: 600px) {
        padding-bottom: 10%;

    }
`;

const Imagens = styled.img`
    width: 100%;
    height: 70px;
`;

const Titulo = styled.h1`
    font-size: 1.8em;
    text-align: center;
`;

const Container = styled.div`
    width: 50%;
    display: flex;
    flex-direction: rown; 
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    margin-left: 25%;

    @media(max-width: 600px) {
        flex-direction: column;
        padding: 0;
    }
`;

const Icone = styled.img`
    width: 60px;
    height: 60px;
    margin-bottom: 5%;
    border: 2px solid #7a7a7a;
    filter: invert(30%);
    border-radius: 100%;
    padding: 0.3em;
    margin-left: 25%;

    &:hover {
        cursor: pointer;
    }

    
    @media(max-width: 600px) {
        margin-top: 10%;
        margin-bottom: 0px;
        margin-left: 32%;
    }
`;

const Iconetelefone = styled(Icone)`
    @media(max-width: 600px) {
        margin-left: 25%;
    }
`;

const Contato = () => {
    return(
        <Secao id={'contato'}>
            <Imagens src={teste} />
            <Titulo>Entre em contato conosco e peça orçamento:</Titulo>
            <Container>
                    <div>
                        <a href='https://www.google.com.br/maps/place/Rua+Manipu%C3%A7%C3%A1,+94+-+c2+-+Cidade+Ademar,+S%C3%A3o+Paulo+-+SP,+04405-180/@-23.6734107,-46.6576511,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce454b8d548bc3:0x33f96948085ed50!8m2!3d-23.6734156!4d-46.6554624' >
                            <Icone src={marcador} />
                        </a>
                        <p>Rua Manipuça, 94-C2</p>
                    </div>
                    <div>
                        <a href='tel: 11 2845 1522' >
                            <Iconetelefone src={telefone} />
                        </a>
                    <p>(11) 2845-1522</p>
                </div>
                <div>
                    <a href='mailto: contato@lait.com.br' >
                        <Icone src={email} />
                    </a>
                    <p>contato@lait.com.br</p>
                </div>
            </Container>
        </Secao>
    );
};

export default Contato;