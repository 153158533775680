import React from "react";
import { GlobalStyle } from "./components/GlobalStyle";
import Navegacao from "./components/Navegacao";
import PrimeiraSecao from "./components/Secao";
import Servico from "./components/Servicos";
import Plenario from "./components/Plenario";
import Clientes from "./components/Clientes";
import Contato from "./components/Contato";

function App() {
  return (<>
      <GlobalStyle />
      <Navegacao />
      <PrimeiraSecao />
      <Plenario />
      <Servico />
      <Clientes />
      <Contato />
    </>
  );
}

export default App;
