import React, { useState } from "react";
import styled from 'styled-components';

import logo from '../assets/logo.jpg';
import menu from '../assets/menu/menu.png';
import Menu from "./Menu";

const Nav = styled.nav`
    width: 100%;
    height: 6vh;
    background-color: #fdfdfd;
    border-bottom: 2px solid #BA292F;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    z-index: 2;
    
    @media(max-width: 600px) {
        height: 10vh;
        padding: 0 10%;
    }
`;

const Logo = styled.img`
    height: 90%;
`;

const Container = styled.div`
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
    font-weight: bold;
`;

const Links = styled.a`
    color: #8D807D;
    height: 100%;
    width: 16.66%;
    text-align: center;
    padding-top: 1.2vh;

    &:hover {
        background-color: #BA292F;
        color: #fdfdfd;
        cursor: pointer;
        transition: 0.85s;
    }

    @media(max-width: 600px) {
        display: none;
    }
`;

const Botao = styled.img`
    width: 45px;
    height: 45px;
    filter: invert(40%);
    display: none;

    @media(max-width: 600px) {
        display: flex;
    }
`;

const Navegacao = () => {

    const [showMenu, setShow] = useState(false);

    return(<>
        <Nav>
            <Logo src={logo} />
            <Container>
                <Links href={'#inicio'}>Início</Links>
                <Links href={'#plenario'}>Plenário</Links>
                <Links href={'#servicos'}>Serviços</Links>
                <Links href={'#clientes'}>Clientes</Links>
                <Links href={'#contato'}>Contato</Links>
            </Container>
            <Botao src={menu} 
                onClick={() => {
                    setShow((menu) => !menu);
                }}
                
            />
        </Nav>
        {
            showMenu ? <Menu /> : null
        }
    </>
    );
}

export default Navegacao;
