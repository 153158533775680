import React from "react";
import styled from "styled-components";

import iconePlenarioUm from '../assets/plenario/icones/television.png';
import imagemPlenarioUm from '../assets/plenario/video-wall.png';

import iconePlenarioDois from '../assets/plenario/icones/gestao-de-pastas.png';
import imagemPlenarioDois from '../assets/plenario/plenario2.png';

import iconePlenarioTres from '../assets/plenario/icones/youtube.png';
import imagemPlenarioTres from '../assets/plenario/plenario3.png';

import iconePlenarioQuatro from '../assets/plenario/icones/caixas-de-som.png';
import imagemPlenarioQuatro from '../assets/plenario/plenario4.png';

import CardPlenario from "./CardPlenario";

const Secao = styled.section`
    width: 100%;
    height: auto;
    padding: 5% 10%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;    
    background-color: #f7f7f7;
`;

const Titulo = styled.h1`
    font-size: 2.2em;
    text-transform: uppercase;
    margin-bottom: 2%;
    text-align: center;
    color: #7a7a7a;
`;

const SubTitulo = styled.h2`
    font-size: 1.5em;
    text-transform: uppercase;
    margin-bottom: 5%;
    text-align: center;
    color: #7a7a7a;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media(max-width: 600px) {
        flex-direction: column;
    }

`;

const Plenario = () => {
    return(
        <Secao id={'plenario'}>
            <Titulo>Nossos serviços</Titulo>
            <SubTitulo>soluções para plenário</SubTitulo>
            <SubTitulo>SISTEMA INTEGRADO DE GESTÃO PARLAMENTAR</SubTitulo>
            <Container>
                <CardPlenario
                    icone={iconePlenarioUm}
                    titulo={'IP VIDEOWALL'}
                    imagem={imagemPlenarioUm}
                />
                <CardPlenario
                    icone={iconePlenarioDois}
                    titulo={'gestão'}
                    imagem={imagemPlenarioDois}
                />
                <CardPlenario
                    icone={iconePlenarioTres}
                    titulo={'transmissão online'}
                    imagem={imagemPlenarioTres}
                />
                <CardPlenario
                    icone={iconePlenarioQuatro}
                    titulo={'SONORIZAÇÃO'}
                    imagem={imagemPlenarioQuatro}
                />
            </Container>
        </Secao>
    );
};

export default Plenario;