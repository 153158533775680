import React from "react";
import styled from "styled-components";


const Card = styled.article`
    width: 18%;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 15px 0px #363636;
    background-color: #fdfdfd;

    @media(max-width: 600px) {
        width: 90%;
        margin: 1em 0;
    }
`;

const IconeCard = styled.img`
    width: 15%;
    margin-top: 1em;
    filter: invert(30%);
`;

const ImagemCard = styled.img`
    width: 100%;
    height: 280px;
    margin-top: 1em;
`;

const TiutloCard = styled.h3`
    font-size: 1.1em;
    text-align: center;
    text-transform: uppercase;
    color: #7a7a7a;
    margin-top: 1em;
`;

const Descricao = styled.p`
    margin: 1em;
    font-size: 1.2em;
    text-align: center;
    color: #7a7a7a;
`;

const CardServico = ({ icone, titulo, imagem, descricao }) => {
    return(
        <Card>
            <IconeCard src={icone} />
            <TiutloCard>{titulo}</TiutloCard>
            <ImagemCard src={imagem} />
            <Descricao>{descricao}</Descricao>
        </Card>
    );
};

export default CardServico;