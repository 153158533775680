import React from "react";
import styled from "styled-components";


const Card = styled.article`
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border-radius: 100%;
    box-shadow: 0px 0px 15px 0px #363636;
    background-color: #fdfdfd;

    @media(max-width: 600px) {
        width: 300px;
        margin: 1em 0;
    }
`;

const IconeCard = styled.img`
    width: 15%;
    margin-top: 1em;
    filter: invert(30%);

`;

const ImagemCard = styled.img`
    width: 65%;
`;

const TiutloCard = styled.h3`
    text-align: center;
    text-transform: uppercase;
    margin: 0.8em 0 1em 0;
    color: #7a7a7a;
`;

const CardPlenario = ({ icone, titulo, imagem }) => {
    return(
        <Card>
            <IconeCard src={icone} />
            <TiutloCard>{titulo}</TiutloCard>
            <ImagemCard src={imagem} />
        </Card>
    );
};

export default CardPlenario;