import React from "react";
import styled from "styled-components";

import clienteUm from '../assets/clientes/cliente1.png';
import clienteDois from '../assets/clientes/cliente2.png';
import clienteTres from '../assets/clientes/cliente3.png';
import clienteQuatro from '../assets/clientes/cliente4.png';
import clienteCinco from '../assets/clientes/cliente5.png';
import clienteSeis from '../assets/clientes/cliente6.png';
import clienteSete from '../assets/clientes/cliente7.png';
import clienteOito from '../assets/clientes/cliente8.jpg';
import clienteNove from '../assets/clientes/cliente9.PNG';
import clienteDez from '../assets/clientes/cliente10.png';

import teste from '../assets/teste2.png';

const Secao = styled.section`
    width: 100%;
    height: auto;
    padding-top: 5%;
    background-color: '#fdfdfd';
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const Titulo = styled.h1`
    font-size: 2em;
    text-transform: uppercase;
    margin-bottom: 5%;
    text-align: center;
    color: #7a7a7a;
`;

const Imagens = styled.img`
    width: 20%;
    height: 20%;
    margin: 0 2.5%;

    @media(max-width: 600px) {
        width: 100%;
        margin: 5% 0;
    }
`;

const ImagensFormatada = styled.img`
    width: 20%;
    height: 35%;
    margin: 0 2.5%;

    @media(max-width: 600px) {
        width: 100%;
        margin: 5% 0;
    }
`;

const ContainerComLegenda = styled.div`    
    width: 25%;
    height: 35%;
    margin: 0 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(max-width: 600px) {
        width: 100%;
        margin: 5% 0;
    }
`;

const ImagemComLegenda = styled.img`
    width: 70%;
    height: 35%;
    margin: 2% 2.5%;

    @media(max-width: 600px) {
        width: 100%;
        margin: 5% 0;
    }
`;

const Container = styled.div`
    width: 65%;
    display: flex;
    flex-direction: rown; 
    justify-content: center;
    flex-wrap: wrap;

    @media(max-width: 600px) {
        width: 90%;
        flex-direction: column;
        flex-wrap: nowrap;
    }
`;

const Imagem = styled.img`
    width: 100%;
    height: 70px;

    @media(max-width: 600px) {
        height: 25px;
    }
`;

const Clientes = () => {
    return(
        <Secao id={'clientes'}>
            <Titulo>EMPRESAS QUE CONTAM COM A GENTE...</Titulo>
            <Container>
                <Imagens src={clienteUm} />
                <Imagens src={clienteDois} />
                <ImagensFormatada src={clienteTres} />
                <ImagensFormatada src={clienteQuatro} />
                <ContainerComLegenda>
                    <ImagemComLegenda src={clienteCinco} />
                    <p>Câmara Municipal de Mauá</p>
                </ContainerComLegenda>
                <Imagens src={clienteSeis} />
                <Imagens src={clienteSete} />
                <ContainerComLegenda>
                    <ImagemComLegenda src={clienteOito} />
                    <p>Câmara Municipal de Arujá</p>
                </ContainerComLegenda>
                <Imagens src={clienteNove} />
                <Imagens src={clienteDez} />
            </Container>
            <Imagem src={teste} />
        </Secao>
    );
};

export default Clientes;