import React from 'react';
import styled from 'styled-components';

import logo from '../assets/lait11.jpg';

const Fundo = styled.div`
    width: 100%;
    height: 70vh;
    background: rgb(248,248,255);
    background: linear-gradient(180deg, rgba(248,248,255, 0.5) 0%, rgba(186,41,47,1) 85%, rgba(128,0,0,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 600px) {
        height: 60vh;
    }
`;

const Logo = styled.img`
    height: 60%;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px #fdfdfd;

    @media(max-width: 600px) {
        height: 40%;
    }
`;

const PrimeiraSecao = () => {
    return( 
        <Fundo  id={'inicio'}>
            <Logo src={logo} />
        </Fundo>
    );
}

export default PrimeiraSecao;