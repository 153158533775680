import React from "react";
import styled from "styled-components";
import CardServico from "./CardServico";

import imagemServicoUm from '../assets/servicos/servico-1.jpg';
import iconeServicoUm from '../assets/servicos/icones/mapa-do-site.png';

import imagemServicoDois from '../assets/servicos/servico-2.jpg';
import iconeServicoDois from '../assets/servicos/icones/data-center.png';

import imagemServicoTres from '../assets/servicos/servico-3.jpg';
import iconeServicoTres from '../assets/servicos/icones/porta-aberta.png';

import imagemServicoQuatro from '../assets/servicos/servico-4.jpg';
import iconeServicoQuatro from '../assets/servicos/icones/tecnologia.png';

import imagemServicoCinco from '../assets/servicos/servico-5.png';
import iconeServicoCinco from '../assets/servicos/icones/varredura-do-olho.png';

const Secao = styled.section`
    width: 100%;
    height: auto;
    padding: 5% 7%;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid rgba(186,41,47, 0.5);
    border-bottom-right-radius: 20%;

    @media(max-width: 600px) {
        border-bottom: 5px solid rgba(186,41,47, 0.5);
        border-top: 5px solid rgba(186,41,47, 0.5);
        border-radius: 50px;    
   
    }

`;

const Titulo = styled.h1`
    font-size: 2.2em;
    text-transform: uppercase;
    margin-bottom: 5%;
    text-align: center;
    color: #7a7a7a;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media(max-width: 600px) {
        flex-direction: column;
    }

`;

const Servico = () => {
    return(
        <Secao  id={'servicos'}>
            <Container>
                <CardServico 
                    icone={iconeServicoUm}
                    titulo={'Cabeamento Estruturado'}
                    imagem={imagemServicoUm}
                    descricao={'Elaboração de Projetos e Implantação de redes de cabeamento | fibra óptica. Rede Voz. Certificação / Projetos'}
                />
                <CardServico 
                    icone={iconeServicoTres}
                    titulo={'Controle de Acesso'}
                    imagem={imagemServicoTres}
                    descricao={'Elaboração de Projetos e Implantação de controle de acesso e automação.'}
                />
                <CardServico 
                    icone={iconeServicoQuatro}
                    titulo={'Governança de ti'}
                    imagem={imagemServicoQuatro}
                    descricao={'Solução inovada para o crescimento dos seus negócios'}
                />
                <CardServico 
                    icone={iconeServicoCinco}
                    titulo={'sistema de cftv'}
                    imagem={imagemServicoCinco}
                    descricao={'Implantação de Câmeras IP e Analógicas.'}
                />
            </Container>
        </Secao>
    );
};

export default Servico;